@import "colors";

.btn.btn-primary,
.btn.btn-success {
  background-color: $accent-color;
  border-color: $accent-color;
  color: #fff;

  &:hover {
    background-color: darken($accent-color, 3%);
    border-color: darken($accent-color, 3%);
    color: #fff;
  }
}

.btn.btn-invert,
.btn.btn-info,
.btn.btn-default,
.btn-box-tool {
  background-color: $primary-color;
  border-color: $primary-color;
  color: #fff !important;

  &:hover {
    background-color: darken($primary-color, 3%) !important;
    border-color: darken($primary-color, 3%) !important;
    color: #fff;
  }
}

a {
  color: darken($accent-color, 5%);
}

.btn.btn-warning {
  background-color: $warn-color;
  border-color: $warn-color;
}

.btn.btn-danger {
  background-color: $danger-color;
  border-color: $danger-color;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  border-color: $primary-color;
}