$primary-color: #063;
$primary-color-lighter: #1A7648;
$primary-front-color: #FFF;
$secondary-color: #1A7648;
$accent-color: #F60;
$accent-color-darker: #DB5800;
$accent-color-lighter: #F72;
$gray: #A2A2A2;
$gray-darker: #666;
$gray-lighter: #CFCFCF;
$background-color: #F2F7F5;
$success-color: #21916C;
$danger-color: #d73925;
$danger-color-lighter: #c9302c;
$failure-color: $danger-color;
$info-color: #69B;
$warn-color: #FDAB48;
$hover-color: $primary-color-lighter;
$hover-front-color: #FFF;
