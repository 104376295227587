@import "../../layout/scss/colors";
@import "../../layout/scss/buttons";

$left-side-background-color: #042;

body.skin-blue .main-sidebar,
body.skin-blue .left-side,
.skin-blue header.main-header .navbar,
.sidebar-menu.nav > li.dropdown > a{
  background-color: $left-side-background-color;
}

.sidebar-menu.nav > li.active > a {
  background-color: lighten($left-side-background-color, 5);
}

.skin-blue .main-header .logo {
  background-color: darken($left-side-background-color, 5);
}

body.skin-blue .sidebar a,
.sidebar-menu.nav > li > a {
  &:hover, &:focus {
    background-color: lighten($left-side-background-color, 5);
  }
}

.sidebar-menu.nav > li.dropdown.active.open > a,
.sidebar-menu.nav > li.dropdown.active.open > ul.dropdown-menu a:hover,
.sidebar-menu.nav > li.dropdown.open > a,
.sidebar-menu.nav > li.dropdown.open > ul.dropdown-menu a:hover,
.sidebar-menu.nav > li.dropdown > a:hover,
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: lighten($left-side-background-color, 5);
}

.sidebar-menu .dropdown-menu {
  background-color: darken($left-side-background-color, 5);
  border-color: lighten($left-side-background-color, 20);
}

.field-expert-languages .btn.dropdown-toggle {
  background-color: $primary-front-color !important;
  color: #555 !important;
  border-color: #BBB !important;
}

.fpt-box2 button.btn-default:hover {
  background-color: $gray-lighter !important;
}

.media.sell i {
  color: $primary-front-color;
}
